import React from "react";
import data from "../assets/data/interests.json";
import chess from "../assets/img/interests/chess_interest.jpg";
import photo from "../assets/img/interests/photo_interest.jpg";
;

function Interest() {
  const images = {
    chess,
    photo,
  };

  return (
    <div className="section" id="interests">
      <h1>My Interests</h1>
      <div className="line-dec" /> 
      {data.map((interest, index) => {
        const isOdd = index % 2 !== 0;
        return (
          <div className="interests-container" key={index}>
            <div className={`interests-item ${isOdd ? "odd" : ""}`}>
              <div className="content-box">
                <h2>
                  {interest.title}
                  <i className={`fa-solid fa-${interest.icon}`}></i>
                </h2>
                <p>{interest.text}</p>
              </div>
              <div className="img-container">
                <img
                  src={images[interest.name]}
                  alt={`${interest.name} Image`}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Interest;
