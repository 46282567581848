import NavBar from "./components/NavBar.js";
import About from "./components/About.js";
import Skills from "./components/Skills.js";
import Work from "./components/Work.js";
import Interest from "./components/Interest.js";
import Contact from "./components/Contact.js";
import Example from "./components/Tester.js";
import Animation from "./components/Animation.js";

const App = () => {
  return (
    <div id="page-wrapper">
      {/* <Animation/> */}
      <NavBar />
      <div className="main-container">
        {/* <Example /> */}
        <About />
        <Skills />
        <Work />
        <Interest />
        <Contact />
      </div>
    </div>
  );
};

export default App;
