import React, { useState, useEffect } from "react";
import Lottie from "lottie-react"; // Changed this line
import animationData_1 from "../assets/json/animation.json";
import animationData_2 from "../assets/json/animation_2.json";

const AnimationComponent = () => {
  return (
    <div className="animation-box">
      <div className="animation-wrapper-top">
        <Lottie animationData={animationData_2} loop={true} />
      </div>
      <div className="animation-wrapper-bottom">
        <Lottie animationData={animationData_1} loop={true} />
      </div>
    </div>
  );
};

export default AnimationComponent;
