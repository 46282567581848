import React from "react";

const ContactForm = () => {
  return (
    <form
      className="contact-form"
      onubmit="sendEmail(); reset(); return false;"
      id="contact"
      method="post"
      action="https://formsubmit.co/alexlevkov87@gmail.com"
    >
      <div className="input-box">
        <input
          name="name"
          type="text"
          className="form-control"
          id="name"
          placeholder="Your name..."
          required
        />
        <input
          name="email"
          type="text"
          className="form-control"
          id="email"
          placeholder="Your email..."
          required
        />
      </div>
      <div className="input-box">
        <input
          name="subject"
          type="text"
          className="form-control"
          id="subject"
          placeholder="Subject..."
          required
        />
      </div>
      <div className="input-box">
        <textarea
          name="message"
          className="form-control"
          id="message"
          placeholder="Your message..."
          required
        ></textarea>
      </div>
      <div className="input-box">
        <button type="submit" id="form-submit">
          Send Message
        </button>
      </div>
      <input type="hidden" name="_next" value="https://alexlevkov.com" />
    </form>
  );
};

export default ContactForm;
