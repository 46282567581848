import React from "react";
import data from "../assets/data/about.json";
import { motion } from "framer-motion";


function About() {
  return (
    <div className="section" id="about">
      <h1>About Me</h1>
      <div className="line-dec" /> 
      {data.map((item, i) => {
        return (
          <div key={i}>
            <h2>{item.title}</h2>
            <p>{item.text}</p>
          </div>
        );
      })}
    </div>
  );
}

export default About;
