import React from "react";
import data from "../assets/data/skills.json";
import frontEnd from "../assets/svg/front-end.svg";
import frontEndDark from "../assets/svg/front-end-dark.svg";
import backEnd from "../assets/svg/back-end.svg";
import backEndDark from "../assets/svg/back-end-dark.svg";
import tools from "../assets/svg/tools.svg";
import toolsDark from "../assets/svg/tools-dark.svg";
import softSkills from "../assets/svg/soft-skills.svg";
import softSkillsDark from "../assets/svg/soft-skills-dark.svg";

function Skills() {
  const svgs = {
    frontEnd,
    frontEndDark,
    backEnd,
    backEndDark,
    tools,
    toolsDark,
    softSkills,
    softSkillsDark,
  };
  return (
    <div className="section" id="skills">
      <h1>My Skills</h1>
      <div className="line-dec"></div>
      <div className="skills-container">
        {data.map((skill, i) => {
          return (
            <div className={"skill-item " + skill.name.toLowerCase()} key={i}>
              <div className="skill-title-wrap">
                <div className="svg-container-light">
                  <img src={svgs[skill.svgName]} alt={skill.svgName} />
                </div>
                <div className="svg-container-dark">
                  <img src={svgs[skill.svgName + "Dark"]} alt="" />
                </div>
                <h2>{skill.name}</h2>
              </div>
              {skill.text && <p>{skill.text}</p>}
              <div className="skill-box">
                {skill.values.map((value, i) => {
                  return <span key={i}>{value}</span>;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Skills;
