import React, { useState, useRef, useEffect } from "react";
import Scrollspy from "react-scrollspy";
import me from "../assets/img/me/me_profile.jpg";
import ContactBox from "./ContactBox";
import { motion } from "framer-motion";
import Animation from "../components/Animation.js";

function NavBar() {
  const [isDesktop, _] = useState(isDesktopCalc());
  const [isOpen, setIsOpen] = useState(isDesktop ? true : false);
  const navRef = useRef();
  const sectionIds = ["about", "skills", "work", "interests", "contact"];

  function isDesktopCalc() {
    return window.innerWidth >= 768;
  }

  const toggle = (e) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (!(navRef.current.contains(event.target) || isDesktop))
        setIsOpen(false);
    });
  }, []);

  const updateLine = (item) => {
    // a possible feature to add in the future
  };

  const liVariants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0.5, x: -50 },
  };

  const ulVariants = {
    open: {
      opacity: 1,
      x: 0,
      transition: { staggerChildren: 0.1 },
    },
    closed: {
      opacity: 0,
      transition: {
        duration: 1.2,
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
  };

  return (
    <motion.div
      className={`nav-wrapper ${!isDesktop && isOpen ? "" : "close-wrap"}`}
      animate={isOpen ? "open" : "closed"}
      initial={false}
    >
      <MenuToggle toggle={toggle} />
      <Animation />

      <div
        ref={navRef}
        className={`nav-container ${isOpen ? "" : "close-nav"}`}
      >
        <div className="nav-details">
          <div className="profile-img-wrap">
            <img src={me} alt="My Image" />
          </div>
          <h3>Alex Levkov</h3>
          <p>Full Stack Developer</p>
          <ContactBox />
          <div className="page-link-box">
            <motion.ul variants={ulVariants}>
              <Scrollspy
                items={sectionIds}
                currentClassName="is-current"
                offset={-50}
                onUpdate={updateLine}
              >
                <motion.li className="nav-item" variants={liVariants}>
                  <a href="#about">About</a>
                </motion.li>
                <motion.li className="nav-item" variants={liVariants}>
                  <a href="#skills">Skills</a>
                </motion.li>
                <motion.li className="nav-item" variants={liVariants}>
                  <a href="#work">Work</a>
                </motion.li>
                <motion.li className="nav-item" variants={liVariants}>
                  <a href="#interests">Interests</a>
                </motion.li>
                <motion.li className="nav-item" variants={liVariants}>
                  <a href="#contact">Contact </a>
                </motion.li>
              </Scrollspy>
            </motion.ul>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default NavBar;

const Path = (props) => {
  const { variants } = props;
  console.log("variants:", variants);

  return (
    <motion.path
      fill="transparent"
      strokeWidth="3"
      stroke="white"
      strokeLinecap="round"
      {...props}
    />
  );
};

const MenuToggle = ({ toggle }) => (
  <div className="toggle-nav-btn" onClick={toggle}>
    <svg width="20" height="20" viewBox="0 0 20 20" className="menu-btn-svg">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
      />
    </svg>
  </div>
);
