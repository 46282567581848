import React from "react";

const ContactBox = () => {
  return (
    <div>
      <ul className="social-icons">
        <li className="social-item">
          <a href="https://linkedin.com/in/alex-levkov" target="_blank">
            <i className="fa-brands fa-linkedin"></i>
          </a>
        </li>
        <li className="social-item">
          <a href="https://wa.me/+972509300319" target="_blank">
            <i className="fa-brands fa-whatsapp"></i>
          </a>
        </li>
        <li className="social-item">
          <a href="mailto:alexlevkov87@gmail.com" target="_blank">
            <i className="fa fa-envelope"></i>
          </a>
        </li>
        <li className="social-item">
          <a href="https://github.com/AlexLevkov" target="_blank">
            <i className="fa-brands fa-github"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ContactBox;
