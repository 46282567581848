import React from "react";
import data from "../assets/data/work.json";
import FurrFriends from "../assets/img/apps/FurrFriends_app.png";
import WeightWin from "../assets/img/apps/WeightWin_app.png";
import CodeSync from "../assets/img/apps/CodeSync_app.png";

const images = {
  FurrFriends,
  WeightWin,
  CodeSync,
};

function Work() {
  return (
    <div className="section" id="work">
      <h1>My Work</h1>
      <div className="line-dec"></div>
      <div className="work-container">
        {data.map((work, i) => {
          return (
            <div key={i} className="work-item">
              <h2 className="work-item-title">{work.name}</h2>
              <p>{work.text}</p>
              <div className="cta-container">
                <a className="cta-btn-main" href={work.appUrl} target="_blank">
                  <i className="fa-solid fa-play app-icon"></i>
                  Try the App Now
                </a>
                <a className="cta-btn-sec" href={work.codeUrl} target="_blank">
                  <i className="fa-solid fa-code app-icon"></i>
                  View Source Code
                </a>
              </div>
              <div className="img-wrap">
                <a href={work.appUrl} target="_blank">
                  <img
                    src={images[work.name]}
                    alt={work.name + " Image"}
                    className={i === 0 && "first"}
                  />
                </a>
              </div>
              <div className="tag-container">
                {work.tags.map((tag, i) => (
                  <span key={i} className="tag-item">
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Work;
