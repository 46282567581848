import React from "react";
import ContactForm from "./ContactForm.js";
import ContactBox from "./ContactBox.js";

function Contact() {
  return (
    <div className="section" id="contact">
      <h1>Contact Me</h1>
      <div className="line-dec" /> 
      <ContactBox />
      <p>Please leave your message here and I will get back to you soon.</p>
      <ContactForm />
    </div>
  );
}

export default Contact;
